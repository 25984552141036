import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 查询 查询一级
export const getFirstFlow = (params) =>
  // console.log(REST_SERVICE.guide.getFirstFlow)
  request({
    url: REST_SERVICE.guide.getFirstFlow,
    method: 'get',
    params
  });

// 查询三级结构
export const getThirdFlow = (params) =>
  request({
    url: REST_SERVICE.guide.getThirdFlow,
    method: 'get',
    params
  });