import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 获取预约事项
export const getYysx = (params) =>
  request({
    url: REST_SERVICE.visitingService.getYysx,
    method: 'get',
    params
  });
// 获取办事指南
export const showGuide = (params) =>
  request({
    url: REST_SERVICE.guide.showGuide,
    method: 'get',
    params
  });
export const showFiles = (params) =>
  request({
    url: REST_SERVICE.guide.showFiles,
    method: 'get',
    params
  });
// 保存预约信息
export const saveAppointment = (params) =>
  request({
    url: REST_SERVICE.visitingService.initiate,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  });
